import React from "react"
import { graphql } from "gatsby"

import * as styles from "../../styles/page/second.module.scss"
import * as dashboardStyles from "../../styles/page/dashboard.module.scss"
import * as chartStyles from "../../styles/components/chart.module.scss"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"
import Modal from "react-modal"
import specialCharacterReplace from "../../utils/special-character-replace"

import SearchFilter from "../../components/parts/chart/search-filter"
import ImageBox from "../../components/parts/image-box"
import LineFilter from "../../components/parts/chart/line-filter"
import BarGroupedLegendFilterType2 from "../../components/parts/chart/bar-grouped-legend-filter-type2"
import BarStackedChange from "../../components/parts/chart/bar-stacked-change"
import MakerShare from "../../components/parts/chart/maker-share"

// markup
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      s12_1_4 : false
    }

    this.searchInit = {
      filter1Init: 6,
      filter2Init: 0,
      filter3Init: 'market-trend',
      current: props.location.pathname
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : true
    })
  }
  closeModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : false
    })
  }

  componentDidMount() {
    Modal.setAppElement('body');
  }

  render() {
    const markdownRemark = this.props.data.markdownRemark
    const { frontmatter } = markdownRemark
    const { title, description, slug } = frontmatter

    return (
      <Layout>
        <Meta 
          title={title}
          description={description}
          path={slug}
        />

        <BreadCrumb
          parentLink="/dashboard/"
          parentTitle="ダッシュボード"
          currentTitle={title}
        />

        <div className={styles.pageTitle}>
          <h1 className={styles.pageTitleHeading} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(title) }} />
        </div>

        <SearchFilter {...this.searchInit} />
        
        <main className={styles.pageContents}>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <LineFilter id="motorWorldQuantity" chartData="motorWorldQuantity" defaultSelect="stock" width="100%" height="420px"  />
            </div>
            <div className={dashboardStyles.chartAreaItem}>
              <BarGroupedLegendFilterType2 id="motorStockQuantity" chartData="motorStockQuantity" defaultSelect="steps" width="100%" height="420px"  />
            </div>
          </div>
          
          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <BarGroupedLegendFilterType2 id="motorSalesQuantity" chartData="motorSalesQuantity" defaultSelect="steps" width="100%" height="420px"  />
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>車載蓄電池の需要（世界、STEPS/APS）</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s12_1_4"}>
                <img src={'../../images/chart/s12-1-4.png'} alt="車載蓄電池の需要（世界、STEPS/APS）" width={559} />
              </button>
              <ImageBox
                open={this.state.s12_1_4}
                close={this.closeModal}
                name={"s12_1_4"}
                src={'/images/chart/s12-1-4.png'}
                alt="車載蓄電池の需要（世界、STEPS/APS）"
                width={895}
                height={436} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.iea.org/reports/global-ev-outlook-2022" target="_blank" rel="noreferrer noopener">IEA, “Global EV Outlook 2022” を基にNEDOが作成</a></p>
                <p className={chartStyles.chartExplain}>IEAのSTEPS、APSシナリオにおける世界の車載蓄電池の需要推移を示す</p>
              </div>
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <BarStackedChange id="stockCount" chartData="stockCount" defaultChart="steps" type="steps" width="100%" height="420px"  />
            </div>
            <div className={dashboardStyles.chartAreaItem}>
              <BarStackedChange id="newSalesCount" chartData="newSalesCount" defaultChart="steps" type="steps" width="100%" height="420px"  />
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <BarStackedChange id="salesResultCount" chartData="salesResultCount" defaultChart="china" type="china" width="100%" height="420px"  />
            </div>
            <div className={dashboardStyles.chartAreaItem}>
              <BarStackedChange id="evInstallationCount" chartData="evInstallationCount" defaultChart="steps" type="steps" width="100%" height="420px"  />
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <MakerShare id="hydrogenStation" chartData="hydrogenStation" width="100%" height="420px" />
            </div>
            <div className={dashboardStyles.chartAreaItem}>
            </div>
          </div>

        </main>
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
  }
`