import React from "react"
import { StaticQuery, graphql } from "gatsby"
import * as am5 from "@amcharts/amcharts5/"
import * as am5xy from "@amcharts/amcharts5/xy"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"
import * as styles from "../../../styles/components/chart.module.scss"
import _ from "lodash"
import specialCharacterReplace from "../../../utils/special-character-replace"
import canvasMemoryReset from "../../../utils/canvas-memory-reset"

// markup
class BarGroupedLegendFilterType2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: props.chartData,
      dataSourceUrl: props.chartData,
      type: props.defaultSelect
    }

    this.series = []
    this.filePath = "/csv/bar-grouped-legend-filter-type2/" // CSVデータ格納先
    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  // グラフ切り替えメニュー
  handleChange(e) {
    let name = e.target.name
    this.setState({[name]: e.target.value})
    this.handleBlur(e);
  }

  // データソースの切り替え
  handleBlur(e) {
    this.setState({dataSourceUrl: e.target.value})
  }

  // config setter
  setConfig(data) {
    this.config = data
  }

  // config getter
  getConfig() {
    return this.config
  }

  // チャートの設定
  async setChartData() {
    am5.addLicense(process.env.AM_CHARTS_LICENSE)
    
    const chartConfig = this.getConfig()

    let root = am5.Root.new(this.props.id)
    let xLabel = chartConfig.axis[this.state.chartData][this.state.type][0].title
    let xRealName = chartConfig.axis[this.state.chartData][this.state.type][0].realName
    root.setThemes([
      am5themes_Animated.new(root)
    ])
    this.root = root

    // 基本設定
    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "none",
      wheelY: "none",
      layout: root.verticalLayout
    }));
    chart.children.unshift(am5.Label.new(root, {
      text: chartConfig.title[this.state.chartData][this.state.type],
      fontSize: "12px",
      centerX: am5.percent(50),
      x: am5.percent(50)
    }))

    // CSVファイルからデータの読み込み
    let fields = chartConfig.series[this.state.chartData][this.state.type].map((item) => { return item.name})
    let dataSource = `${this.filePath}${this.state.chartData}-${this.state.type}.csv`
    let data = await am5.net.load(dataSource).then(function(result) {
      // CSVパースオプション
      let chartBase = []
      let data = am5.CSVParser.parse(result.response, {
        delimiter: ",",
        reverse: false,
        skipEmpty: true,
        useColumnNames: true
      });
      // 型変換処理
      
      let processor = am5.DataProcessor.new(root, {
        numericFields: fields
      });
      processor.processMany(data);
      // グルーピング用にCSVデータを加工
      data.forEach((obj, id) => {
        chartBase[id] = {}
        for (let k in obj) {
          if (k === xRealName) {
            chartBase[id][k] = `${obj['provider']} ${obj[xRealName]}`
            chartBase[id]['realName'] = obj[xRealName]
          } else {
            chartBase[id][k] = obj[k]
          }
        }
      })
      return chartBase
    })

    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      fontSize: "11px"
    })

    // X座標設定
    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      maxDeviation: 0,
      categoryField: xLabel,
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(root, {})
    }))
    this.xAxis = xAxis

    // Y座標設定
    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      min: chartConfig.axis[this.state.chartData][this.state.type][1].min,
      max: chartConfig.axis[this.state.chartData][this.state.type][1].max,
      numberFormat: "#,###",
      strictMinMax: true,
      calculateTotals: true,
      renderer: am5xy.AxisRendererY.new(root, {}),
      tooltip: am5.Tooltip.new(root, {})
    }))
    let rangeDataItem = yAxis.makeDataItem({
      value: chartConfig.axis[this.state.chartData][this.state.type][1].range
    })
    yAxis.createAxisRange(rangeDataItem)
    
    let yRenderer = yAxis.get('renderer')
    yRenderer.labels.template.setAll({
      fontSize: "11px"
    })

    yAxis.children.unshift(am5.Label.new(root, {
      rotation: -90,
      text: chartConfig.axis[this.state.chartData][this.state.type][1].title,
      y: am5.p50,
      centerX: am5.p50,
      fontSize: "11px",
    }))
    this.yAxis = yAxis

    this.chart = chart

    // Legendの指定
    let legend = chart.children.push(am5.Legend.new(root, {
      x: am5.percent(0),
      centerX: am5.percent(0),
      width: am5.percent(100),
      layout: root.gridLayout,
      clickTarget: "none"
    }))

    legend.labels.template.setAll({
      fontSize: 11
    })
    legend.markers.template.setAll({
      width: 14,
      height: 14
    })
    
    // X座標のラベルを指定
    xRenderer.labels.template.setAll({
      text: "{realName}",
      fontSize: "11px"
    });

    // Providerでグルーピング
    let byName = _.groupBy(data, 'provider')
    this.createProvider(byName)

    xAxis.data.setAll(data)

    // シリーズ設定
    chartConfig.series[this.state.chartData][this.state.type].forEach(item => {
      this.createSeries(data, legend, item.name, item.fill, item.type)
    })    
  }

  // Providerの指定
  createProvider(data) {
    let xAxis = this.xAxis
    Object.keys(data).forEach((key) => {
      let range = xAxis.makeDataItem({})
      xAxis.createAxisRange(range)
      range.set("category", data[key][0].category);
      range.set("endCategory", data[key][data[key].length - 1].category)
      
      let label = range.get("label")
      label.setAll({
        text: data[key][0].provider,
        dy: 20
      })
      
      let tick = range.get("tick");
      tick.setAll({ visible: true, strokeOpacity: 0.3, length: 40, location: 0 });
    
      //let grid = range.get("grid");
      //grid.setAll({ strokeOpacity: 1 });
    })
  }

  createSeries(data, legend, name, fill, type) {
    // シリーズの設定
    let root = this.root
    let chart = this.chart
    let xAxis = this.xAxis
    let yAxis = this.yAxis
    let categoryLabel = this.getConfig().axis[this.state.chartData][this.state.type][0].title

    if (type === 'bar') {
      let barTooltip = this.getConfig().axis[this.state.chartData][this.state.type][1].tooltip
      // 棒グラフの設定
      let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: name,
          categoryXField: categoryLabel
        })
      );
      series.columns.template.setAll({
        tooltipText: barTooltip,
        fill: fill,
        stroke: fill,
        width: am5.percent(50),
        tooltipY: am5.percent(5),
        templateField: "columnSettings"
      });
      series.data.setAll(data);
      series.appear();    
    }
    legend.data.setAll(chart.series.values);
  }

  componentDidMount() {
    this.setChartData()
  }
  
  componentDidUpdate(prevState) {
    if (prevState.dataSourceUrl !== this.state.dataSourceUrl ) {
      canvasMemoryReset(`#${this.props.id} canvas`)
      this.root.dispose()
      this.setChartData()
    }
  }

  componentWillUnmount() {
    canvasMemoryReset(`#${this.props.id} canvas`)
    if (this.root) {
      this.root.dispose()
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query allBarGroupedLegendFilterType2Query {
            settings: allBarGroupedLegendFilterType2Json {
              edges {
                node {
                  select {
                    motorStockQuantity {
                      label
                      name
                    }
                    motorSalesQuantity {
                      label
                      name
                    }
                  }
                  axis {
                    motorStockQuantity {
                      steps {
                        label
                        max
                        min
                        range
                        title
                        type
                        tooltip
                        realName
                      }
                      aps {
                        label
                        max
                        min
                        range
                        title
                        type
                        tooltip
                        realName
                      }
                    }
                    motorSalesQuantity {
                      steps {
                        label
                        max
                        min
                        range
                        title
                        type
                        tooltip
                        realName
                      }
                      aps {
                        label
                        max
                        min
                        range
                        title
                        type
                        tooltip
                        realName
                      }
                    }
                  }
                  series {
                    motorStockQuantity {
                      aps {
                        fill
                        name
                        label
                        type
                      }
                      steps {
                        fill
                        label
                        name
                        type
                      }
                    }
                    motorSalesQuantity {
                      aps {
                        fill
                        name
                        label
                        type
                      }
                      steps {
                        fill
                        label
                        name
                        type
                      }
                    }
                  }
                  head {
                    motorStockQuantity
                    motorSalesQuantity
                  }
                  title {
                    motorStockQuantity {
                      aps
                      steps
                    }
                    motorSalesQuantity {
                      aps
                      steps
                    }
                  }
                  source {
                    motorStockQuantity {
                      explain
                      sourceDate
                      sourceLink
                      sourceText
                    }
                    motorSalesQuantity {
                      explain
                      sourceDate
                      sourceLink
                      sourceText
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            { this.setConfig(data.settings.edges[0].node) }
            <div className={styles.chartTitle}>
              <h2 dangerouslySetInnerHTML={{ __html: specialCharacterReplace(data.settings.edges[0].node.head[this.state.chartData]) }} />
            </div>
            <div className={styles.chartFilter}>
              <div className={styles.chartFilterInner}>
                <select name="type" value={this.state.type} id={`${this.state.chartData}Type`} onChange={this.handleChange}>
                  { data.settings.edges[0].node.select[this.state.chartData].map((item, id ) => {
                    return <option key={id} value={item.label}>{item.name}</option>
                  }) }
                </select>
              </div>
            </div>
            <div id={this.props.id} style={{ width: this.props.width, height: this.props.height }}></div>
            <div className={styles.chartNote}>
              <p className={styles.chartSource}>出所）<a href={data.settings.edges[0].node.source[this.state.chartData].sourceLink} target="_blank" rel="noreferrer noopener">{data.settings.edges[0].node.source[this.state.chartData].sourceText}</a>{data.settings.edges[0].node.source[this.state.chartData].sourceDate}</p>
              <p className={styles.chartExplain}>{data.settings.edges[0].node.source[this.state.chartData].explain}</p>
              <p><a className={styles.chartDataDownload} href={`${this.filePath}${this.state.chartData}-${this.state.type}.csv`}>データダウンロード</a></p>
            </div>
          </>
        )}
      />
    )
  }
}
export default BarGroupedLegendFilterType2
